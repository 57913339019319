import { createContext, ReactNode, useContext, useEffect } from "react";
import { UseDisclosureReturn, useDisclosure } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

interface SideBarContextProps {
  children: ReactNode;
}

type SideBarDrawerContextData = UseDisclosureReturn;

const SideBarContext = createContext({} as SideBarDrawerContextData);

export function SideBarProvider({ children }: SideBarContextProps) {
  const disclosure = useDisclosure();
  const location = useLocation();

  useEffect(() => {
    disclosure.onClose();
  }, [location.pathname]);

  return (
    <SideBarContext.Provider value={disclosure}>
      {children}
    </SideBarContext.Provider>
  );
}

export function useSideBar() {
  return useContext(SideBarContext);
}
