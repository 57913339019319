import React, { FC, useCallback, useState } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,

  SimpleGrid,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../Form/Input'
import api from '../../Services/api'
import { useAuth } from '../../Hooks/Auth'
import { Loader } from '../Loader'

interface Props {
  isOpen: boolean
  cancelFunction: (data?: any) => void
  nextFunction: (data?: any) => any
  title: string
  description?: string
  cancelText?: string
  nextText?: string
  cancelScheme?: string
  nextScheme?: string
  thirdButton?: boolean
  thirdButtonText?: string
  thirdButtonFunction?: (data?: any) => void
}

const ResetPassSchema = Yup.object().shape({
  password: Yup.string().required('Preencha a senha'),
  password_confirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'As senhas devem ser iguais'
  ).required('Preencha a confirmação de senha')
})

const ResetPasswordModal: FC<Props> = ({
  isOpen,
  cancelFunction,
  nextFunction,
  title,
  description,
  cancelText,
  nextText,
  cancelScheme,
  nextScheme,
  thirdButton,
  thirdButtonText,
  thirdButtonFunction
}) => {
  const cancelRef = React.useRef<any>()
  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(ResetPassSchema)
  })
  const toast = useToast()
  const { user, updateGlobalUser } = useAuth()

  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      const {
        password
      } = values

      try {
        await api.put(`/collaborators/password/${user.id}`, { password })

        toast({
          title: 'Senha redefinida.',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })

        await updateGlobalUser({ resetPass: 0 })
        setLoading(false)

        nextFunction()
      } catch (error) {
        console.log('error: ', error)
        toast({
          title: error?.response?.data?.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      }
    },
    [user, nextFunction, toast, updateGlobalUser]
  )

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={cancelFunction}
      isCentered
    >
        {loading && <Loader />}
      <AlertDialogOverlay>
      <Box
          as="form"
            onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          p="8"
        >
        <AlertDialogContent bgColor="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <Input
                label="Nova Senha"
                placeholder="Preencha a senha"
                errors={errors.password}
                {...register('password')}
                type="password"
              />

<Input
                label="Confirmar Nova Senha"
                placeholder="Preencha novamente a senha"
                errors={errors.password_confirmation}
                {...register('password_confirmation')}
                type="password"
              />

            </SimpleGrid></VStack>
          </AlertDialogBody>

          <AlertDialogFooter>

            <Button
            type='submit'
              colorScheme={nextScheme || 'red'}

              ml={3}
            >
             Salvar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
        </Box>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export { ResetPasswordModal }
