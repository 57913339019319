import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./Styles/theme";
import AppProvider from "./Hooks/index";

import Routes from "./Routes";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <AppProvider>
          <Routes />
        </AppProvider>
      </Router>
    </ChakraProvider>
  );
}

export default App;
