/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useRef, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack,
  Icon,
  Text,
  Modal
} from '@chakra-ui/react'
import { v4 as uuid } from 'uuid'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { TextArea } from '../../Components/Form/TextArea'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {
  DragAndDrop,
  DragAndDropExportedProps
} from '../../Components/Form/DragAndDrop'
import { DataPropsVideos } from '../Videos'
import { Checkbox } from '../../Components/Form/Checkbox'
import { RiAddLine, RiDeleteBack2Fill } from 'react-icons/ri'
import { TiDeleteOutline } from 'react-icons/ti'

interface createCategory {
  index?: number
  name: string
  description: string
}

interface OptionsProps {
  label: string
  value: string
}

const createFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  description: Yup.string().required('Descrição é obrigatório')
})

const updateFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  description: Yup.string().required('Descrição é obrigatório')
})

interface OptionProps {
  id: string
  item_id: string
  name?: string
}

interface ItemProps {
  id: string
  name?: string
  items?: OptionProps[]
}

const ProductsCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [allVideos, setAllVideos] = useState<DataPropsVideos[]>([])
  const [initialCover, setInitialCover] = useState(undefined)
  const params = useParams()
  const [playlist, setPlaylist] = useState<any>({})
  const [items, setItems] = useState<ItemProps[]>([])
  const [options, setOptions] = useState<OptionProps[]>([])

  const toast = useToast()
  const DragRef = useRef<DragAndDropExportedProps>(null)
  console.log('params: ', params)
  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue } = useForm({
    // resolver: yupResolver(createFormSchema)
  })
  const { errors } = formState

  const handeCreateNewData: SubmitHandler<any> = useCallback(
    async (values) => {
      console.log('values: ', values)

      setLoading(true)
      try {
        const payload = {
          name: values.name,
          items: items.map(item => {
            const payloadItem = {
              id: item.id,
              name: values[`name-item-${item.id}`],
              description: values[`description-item-${item.id}`],
              options: item.items?.map(option => {
                const payloadOption = {
                  id: option.id,
                  name: values[`name-option-${option.id}-item-${option.item_id}`]
                }

                return payloadOption
              })
            }

            return payloadItem
          })
        }

        console.log('payload: ', payload)

        params?.id
          ? await api.put(`/products/${params?.id}`, payload)
          : await api.post('/products', payload)

        toast({
          title: params?.id ? 'Editado com sucesso' : 'Cadastrado com sucesso',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })

        navigate('/products')
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
    [toast, params, navigate, items]
  )

  const loadItem = useCallback(async () => {
    try {
      const { slug } = params
      if (!slug) return
      const resp = await api.get(`/playlist/${slug}`)
      const data: any = resp.data
      console.log('loadItem: ', resp.data)

      setValue('name', data.playlist.name)
      setValue('description', data.playlist.description)

      const videosIds = resp.data.videos.map((i: any) => i.id)
      setPlaylist(data.playlist)
      setValue('videos', videosIds)

      if (data.playlist.cover_url) {
        setInitialCover(data.playlist.cover_url)
      }
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params])

  useEffect(() => {
    if (params.slug) {
      loadItem()
    }
  }, [params])

  const handleNewItem = useCallback(() => {
    const payload = {
      id: uuid(),
      name: '',
      items: []
    }
    setItems(prev => [...prev, payload])
  }, [])

  const handleNewOption = useCallback((item_id: string) => {
    const item = items.findIndex(i => i.id === item_id)

    const newArry = [...items]

    const currentItems = newArry[item].items

    const payload = {
      id: uuid(),
      item_id,
      name: ''
    }

    newArry[item].items = (currentItems != null) ? [...currentItems, payload] : [payload]

    setItems([...newArry])
  }, [items])

  const handleDeleteOption = useCallback((option: OptionProps) => {
    const item = items.findIndex(i => i.id === option.item_id)

    const newArry = [...items]

    const currentItems = newArry[item].items

    newArry[item].items = currentItems !== null ? currentItems?.filter(i => i.id !== option.id) : []

    setItems([...newArry])
  }, [items])

  return (
    <Box>
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handeCreateNewData)}
        >
          <Heading size="lg" fontWeight="normal" color="blue.500">
            Criar novo Produto
          </Heading>
          <Divider my="6" borderColor="blue.700" />

          <VStack spacing={['9', '9']} mb="5">
            <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
              <Input
                label="Nome"
                placeholder="Preencha o nome do Produto (Ex: Cimento)"
                // errors={errors.name}
                {...register('name')}
              />
            </SimpleGrid>

          </VStack>

            <Heading size="md" fontWeight="normal" color="blue.500" my="6" mt="0">
              Opcionais
            </Heading>

            {!items?.length && <Text mb="10" color="black.primary">Você ainda não possui nenhum opcional cadastrado para este produto.</Text>}

          {items.map((item, indexItem: number) => (
              <VStack spacing={['9', '9']} mb="5" bg="green.primary" p="10" key={item.id} borderRadius="8px">
                <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                <Input
                  label="Nome"
                  placeholder="Ex: Estado de conservação"
                  // errors={errors.name}
                  {...register(`name-item-${item.id}`)}
                />

                {/* <TextArea
                  label="Descrição"
                  placeholder="Preencha a descrição"
                  {...register('description')}
                /> */}
                <Input
                  label="Descrição"
                  placeholder="Preencha a descrição (Ex: Após a fabricação)"
                  // errors={errors.name}
                  {...register(`description-item-${item.id}`)}
                  helperText="Opcional"
                />
                </SimpleGrid>

                <Button
                  width="100%"
                  as="button"
                  size="lg"
                  fontSize="sm"
                  bg="black.primary"
                  mb="10"
                  _hover={{ bgColor: 'gray.500' }}
                  color="green.primary"
                  leftIcon={<Icon as={RiAddLine} fontSize="20" />}
                  onClick={() => handleNewOption(item.id)}
                  >
                  Adicionar Opção
                </Button>

                <SimpleGrid spacing={['12', '12']} w="100%" >

                  {item?.items?.map((option) => (
                    <Flex key={option.id} alignItems="flex-end" w="100%">
                       <Input
                        label="Opção"
                        placeholder="Preencha a opção (Ex: 50kg)"
                        // errors={errors.name}
                        {...register(`name-option-${option.id}-item-${option.item_id}`)}

                      />
                      <Button
                          ml="5"
                          as="button"
                          size="sm"
                          fontSize="md"
                          bg="black.primary"
                          height="47px"
                          _hover={{ bgColor: 'gray.500' }}
                          color="white"
                          leftIcon={<Icon as={TiDeleteOutline} fontSize="30" color="white" margin="0" marginInlineEnd={0} />}
                          onClick={() => handleDeleteOption(option)}
                          >
                            Excluir
                        </Button>
                      </Flex>
                  ))}
                </SimpleGrid>
              </VStack>
          ))}

          <Button
            as="button"
            size="lg"
            fontSize="sm"
            bg="black.primary"
            mb="10"
            _hover={{ bgColor: 'gray.500' }}
            color="green.primary"
            leftIcon={<Icon as={RiAddLine} fontSize="20" />}
            onClick={handleNewItem}
            >
            Adicionar Opcional
          </Button>

          {/* <Flex mt={['6', '8']} direction="column">
            {allVideos.map((i) => (
              <Checkbox
                value={i.id}
                {...register('videos')}
                label={`${i.title} - ${i.category}`}
                style={{ padding: 5 }}
              />
            ))}
          </Flex> */}

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              {loading
                ? (
                <Spinner />
                  )
                : (
                <>
                  <Button
                    as="a"
                    bg="red.primary"
                    _hover={{ bgColor: 'gray.500' }}
                    color="white"
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="submit"
                    bg="blue.500"
                    _hover={{ bgColor: 'gray.500' }}
                    color="white"
                    isLoading={formState.isSubmitting}
                  >
                    Salvar
                  </Button>
                </>
                  )}
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { ProductsCreate }
