/* eslint-disable @typescript-eslint/semi */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { BsEye } from 'react-icons/bs'
import { Can } from '../../Components/Can'

interface DataProps {'queues': number;'retrieval': number;'dlq': number}

const Lists: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<DataProps>({} as DataProps)
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(1)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/orders', {
        params: {
          page: pag,
          offset: 20,
          status: 'ERROR'
        }
      })
      console.log('data: ', data)

      setPage(pag)
      setData(data.orders)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar unidades: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  return (

    <Box>

<Flex direction="column" h="100vh" bg="white">
      <Header />

      <Flex direction="column" h="100vh" bg="black.primary">
        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6" backgroundColor="black.primary">
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="black.primary">
              Registros
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>

          </Flex>

          {loading
            ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
              )
            : error
              ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista</Text>
            </Flex>
                )
              : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>

                    <Th>Lista</Th>
                    <Th >Registros</Th>

                  </Tr>
                </Thead>
                <Tbody>
                  {['Principal', 'Reprocessamento', 'Erros'].map((i, index) => (
                    <Tr key={i}>

                      <Td>

                            <Text fontWeight="bold">{i}</Text>

                      </Td>

                      <Td>
                          {index === 0 && <Text fontWeight="bold">{data.queues}</Text> }
                          {index === 1 && <Text fontWeight="bold">{data.retrieval}</Text> }
                          {index === 2 && <Text fontWeight="bold">{data.dlq}</Text> }

                      </Td>

                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />

            </>
                )}
        </Box>
      </Flex>
      </Flex>
      </Flex>
    </Box>

  )
}

export { Lists }
