import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  // fonts: {
  //     heading: 'Roboto',
  //     body: 'Roboto'
  // },
  colors: {
    gray: {
      primary: '#f1f2f2',
      900: '#181b23',
      800: '#1f2029',
      700: '#353646',
      600: '#4b4d63',
      500: '#616480',
      400: '#797d9a',
      300: '#9699b0',
      200: '#b3b5c6',
      100: '#d1d2dc',
      50: '#eeeef2'
    },
    red: {
      primary: '#fe4345'
    },
    green: {
      primary: '#c3d93a'
    },
    black: {
      primary: '#080909'
    },
    wine: {
      primary: '#771731',
      200: '#993b58',
      400: '#590829',
      500: '#480027',
      600: '#380024'
    },
    white: {
      primary: '#FFF',
      secondary: '#fafafa'
    }
  },
  styles: {
    global: {
      body: {
        bg: 'black.primary',
        color: 'gray.500'
      },
      button: {
        outline: 0,
        border: 0,
        outlineOffset: 0
      },
      a: {
        outline: 0,
        border: 0
      },
      '#chakra-toast-manager-top': {
        color: '#fff'
      },
      '.chakra-toast': {
        color: '#fff'
      }
    }
  }
})
