import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'

export interface CategoriesProps {
  id: string
  name: string
  cover?: string
  description: string
  cover_url?: string
  slug?: string
}

export interface VideoProps {
  id: string
  title: string
  description: string
  category_id: string
  cover?: string
  created_at: string
  cover_url?: string
  slug?: string
}

interface PlaylistProps {
  id: string
  name: string
  slug?: string
}

export interface DataProps {
  playlist: PlaylistProps
  videos: VideoProps
}

const ProductsList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<DataProps[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState({} as DataProps)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/playlist', {
        params: {
          pag
        }
      })

      setPage(pag)
      setData(data.data)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar playlist: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const hanleDelete = useCallback(async () => {
    if (!selectedItem || !selectedItem?.playlist?.id) return

    const { id } = selectedItem?.playlist
    try {
      await api.delete(`/playlist/${id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as DataProps)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as DataProps)
    }
  }, [selectedItem, toast, page])

  const closeModal = useCallback(() => {
    setSelectedItem({} as DataProps)
  }, [])

  return (
    <Box>
      {!!selectedItem?.playlist?.id && (
        <AlertDialogCustom
          title="Deletar Playlist"
          isOpen={!!selectedItem?.playlist?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar />

        <Box flex="1" borderRadius={8} bg="white" p="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="lg" fontWeight="normal" color="blue.500">
              Produtos
              {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
            </Heading>
            {/* <SearchBox placeholder="Buscar por nome" /> */}

            <Button
              as="a"
              size="sm"
              fontSize="sm"
              bg="black.primary"
              _hover={{ bgColor: 'gray.500' }}
              color="green.primary"
              leftIcon={<Icon as={RiAddLine} fontSize="20" />}
              href="/products/create"
            >
              Criar novo produto
            </Button>

            {/* <NextLink href="/users/create" passHref>
                            <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                Criar Usuário
                            </Button>
                        </NextLink> */}
          </Flex>

          {loading ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista</Text>
            </Flex>
          ) : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>
                    {/* <Th px={["4","4","6"]} color="gray.300" w="8">
                                        <Checkbox colorScheme="pink" />
                                    </Th> */}
                    <Th>Playlist</Th>

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.playlist.id}>
                      {/* <Td px={["4","4","6"]}>
                                         <Checkbox colorScheme="pink" />
                                     </Td> */}
                      <Td>
                        <Box>
                          <Link color="blue.500">
                            <Text fontWeight="bold">{i.playlist.name}</Text>
                          </Link>
                        </Box>
                      </Td>

                      <Td>
                        <Flex>
                          {isWideScreen
                            ? (
                            <Button
                              as="a"
                              href={`/playlist/create/${i.playlist.slug}`}
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="green"
                              leftIcon={
                                <Icon as={RiPencilLine} fontSize="16" />
                              }
                            >
                              Editar
                            </Button>
                              )
                            : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
                              )}

                          {isWideScreen
                            ? (
                            <Button
                              as="button"
                              onClick={() => setSelectedItem(i)}
                              size="sm"
                              fontSize="sm"
                              colorScheme="red"
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              Excluir
                            </Button>
                              )
                            : (
                            <IconButton
                              colorScheme="red"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineDelete} size="30" />}
                              onClick={() => setSelectedItem(i)}
                            />
                              )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
            </>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export { ProductsList }
