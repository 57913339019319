/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, {
  FC,
  createContext,
  useCallback,
  useState,
  useContext
} from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../Services/api'
import { useToast } from '@chakra-ui/react'

export interface UserProps {
  id: string
  name: string
  department?: string
  email: string
  resetPass: string
  terms: string
  roles: string[]
  permissions: string[]
}

interface AuthData {
  token: string
  user: UserProps
  // config: ConfigProps;
}

interface SignInProps {
  username: string
  password: string
}

interface AuthProps {
  user: UserProps

  signIn: (credentials: SignInProps) => Promise<void>
  signOut: () => void
  updateGlobalUser: (data: any) => Promise<void>
}
const AuthContext = createContext<AuthProps>({} as AuthProps)

export const AuthProvider: FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const Client = '@InPele'
  const toast = useToast()
  const navigate = useNavigate()

  // const history = useHistory();
  const [data, setData] = useState<AuthData>((): any => {
    const token = localStorage.getItem(`${Client}:token`)
    const user = localStorage.getItem(`${Client}:user`)

    if (token && user) {
      api.defaults.headers.common.authorization = `Bearer ${token}`
      return { token, user: JSON.parse(user) }
    }

    return {} as AuthData
  })

  const signIn = useCallback(
    async ({ password, username }: SignInProps) => {
      try {
        const response = await api.post('/login', { password, username })
        const { sessionToken, refreshToken, username: user } = response.data
        api.defaults.headers.common.authorization = `Bearer ${sessionToken}`

        const newUser = {
          ...user
        }

        localStorage.setItem(`${Client}:token`, sessionToken)
        localStorage.setItem(`${Client}:user`, JSON.stringify(newUser))

        setData({ token: sessionToken, user: newUser })
        navigate('/dashboard')
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          variant: 'solid'

        })
      }
    },
    [navigate, toast]
  )

  const signOut = useCallback((): void => {
    localStorage.removeItem(`${Client}:token`)
    localStorage.removeItem(`${Client}:user`)

    setData({} as AuthData)

    navigate('/')
  }, [navigate])

  const updateGlobalUser = useCallback(
    async (dados: UserProps) => {
      try {
        const newUser = {
          ...data.user,
          ...dados
        }

        localStorage.setItem(`${Client}:user`, JSON.stringify(newUser))

        setData(prev => ({ token: prev.token, user: newUser }))
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          variant: 'solid',
          colorScheme: 'yellow'

        })
      }
    },
    [toast, data]
  )

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, updateGlobalUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthProps => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used inside an AuthProvider')
  }

  return context
}
