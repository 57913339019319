import { Text, Box } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { useAuth } from '../../Hooks/Auth'
import { validateUserPermissions } from '../../utils/validateUserPermissions'

interface Props {
  children: ReactNode
  permissions?: string[]
  roles?: string[]
  page?: boolean
}
export function Can ({ children, permissions, roles, page = false }: Props) {
  const { user } = useAuth()
  const hasPermission = validateUserPermissions({ user, permissions, roles })
  if (!hasPermission) {
    if (page) {
      return <Box w="100vw" h="100vh" alignItems="center" justifyContent="center" display="flex"><Text color="white" p={10}>Permissão negada.</Text></Box>
    }

    return null
  }
  return <>{children}</>
}
