/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiCloseCircleFill, RiDeleteBack2Fill, RiDeleteColumn, RiPencilLine, RiUpload2Fill, RiXboxFill } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { MdOutlineRefresh } from 'react-icons/md'

export interface CategoriesProps {
  id: string
  name: string
  cover?: string
  description: string
  cover_url?: string
  slug?: string
}

export interface VideoProps {
  id: string
  title: string
  description: string
  category_id: string
  cover?: string
  created_at: string
  cover_url?: string
  slug?: string
}

interface PlaylistProps {
  id: string
  name: string
  slug?: string
}

export interface DataProps {
  'id': string
  'createdAt': string
  'updatedAt': string
  'orderId': string

  'companyDocument': string
  'registeredOrderIdOnOmie': string
  'errorMessage'?: string
}

const PoisonedRegistersList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<DataProps[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [showModalDestroyAll, setShowModalDestroyAll] = useState(false)
  const [selectedItem, setSelectedItem] = useState({} as DataProps)
  const [selectedItemRefresh, setSelectedItemRefresh] = useState({} as DataProps)
  const toast = useToast()

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/orders', {
        params: {
          page: pag,
          offset: 20,
          status: 'ERROR'
        }
      })

      setPage(pag)
      setData(data.orders)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar registros: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    load(page)
  }, [page])

  const handleDestroyAll = useCallback(async () => {
    try {
      await api.delete('/orders')

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setShowModalDestroyAll(false)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setShowModalDestroyAll(false)
    }
  }, [toast, page, load])

  const hanleDelete = useCallback(async () => {
    if (!selectedItem || !selectedItem?.id) return

    try {
      await api.delete(`/order/${selectedItem.id}`)

      toast({
        title: 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as DataProps)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as DataProps)
    }
  }, [selectedItem, toast, page, load])

  const closeModal = useCallback(() => {
    setSelectedItem({} as DataProps)
  }, [])

  const handleExportRegisters = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get('/poisoned-registers/export', {
        responseType: 'arraybuffer'

      })

      // Criar um Blob a partir dos dados recebidos
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      // Criar um URL temporário para o Blob
      const url = URL.createObjectURL(blob)

      // Criar um link de download
      const link = document.createElement('a')
      link.href = url
      link.download = 'registros-falhas.xlsx'

      // Adicionar o link ao DOM e simular um clique para iniciar o download
      document.body.appendChild(link)
      link.click()

      // Remover o link do DOM
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    } catch (error) {
      console.log('erro ao pegar colaborador: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  const closeModalRefresh = useCallback(() => {
    setSelectedItemRefresh({} as DataProps)
  }, [])

  const hanleRefresh = useCallback(async () => {
    if (!selectedItemRefresh || !selectedItemRefresh?.id) return

    try {
      await api.delete(`/order/${selectedItemRefresh.id}/re-add`)

      toast({
        title: 'Pedido reprocessado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItemRefresh({} as DataProps)
      load(page)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItemRefresh({} as DataProps)
    }
  }, [selectedItemRefresh, toast, page, load])

  return (
    <Box>
      {!!selectedItem?.id && (
        <AlertDialogCustom
          title="Deletar registro da fila?"
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModal}
          nextFunction={hanleDelete}
        />
      )}

{!!selectedItemRefresh?.id && (
        <AlertDialogCustom
          title="Reprocessar pedido?"
          isOpen={!!selectedItemRefresh?.id}
          cancelFunction={closeModalRefresh}
          nextFunction={hanleRefresh}
        />
)}

      {showModalDestroyAll && (
        <AlertDialogCustom
          title="Deletar todos os Registros da fila?"
          isOpen={showModalDestroyAll}
          cancelFunction={() => setShowModalDestroyAll(false)}
          nextFunction={handleDestroyAll}
        />
      )}

      <Flex direction="column" h="100vh" bg="white">
      <Header />

      <Flex direction="column" h="100vh" bg="black.primary">
        <Flex w="100%" my="6" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
          <Sidebar />

          <Box flex="1" borderRadius={8} bg="white" p="8">
            <Flex mb="8" justify="space-between" align="center">
              <Heading size="lg" fontWeight="normal" color="blue.500">
                Registros com erros
                {/* { !loading && isRefetching && <Spinner size="sm" ml="4" color="pink.400" />} */}
              </Heading>
              {/* <SearchBox placeholder="Buscar por nome" /> */}

              <Flex>
                <Button

                      size="sm"
                      fontSize="sm"
                      bg="black.primary"
                      _hover={{ bgColor: 'gray.500' }}
                      color="white"
                      leftIcon={<Icon as={RiCloseCircleFill} fontSize="20" />}
                      onClick={() => setShowModalDestroyAll(true)}
                    >
                      Deletar toda a lista
                    </Button>

                    {/* <Button

                      size="sm"
                      fontSize="sm"
                      bg="black.primary"
                      _hover={{ bgColor: 'gray.500' }}
                      color="white"
                      leftIcon={<Icon as={RiUpload2Fill} fontSize="20" />}

                      marginLeft="15px"
                      onClick={handleExportRegisters}
                    >
                      Exportar para Excel
                    </Button> */}
              </Flex>

              {/* <NextLink href="/users/create" passHref>
                              <Button as="a" size="sm" fontSize="sm" colorScheme="blue" leftIcon={<Icon as={RiAddLine} fontSize="20" />}>
                                  Criar Usuário
                              </Button>
                          </NextLink> */}
            </Flex>

            {loading ? (
              <Flex justify="center">
                <Spinner />
              </Flex>
            ) : error ? (
              <Flex justify="center">
                <Text>Erro ao carregar a lista</Text>
              </Flex>
            ) : (
              <>
                <Table colorScheme="blue">
                  <Thead>
                    <Tr>
                      {/* <Th px={["4","4","6"]} color="gray.300" w="8">
                                          <Checkbox colorScheme="pink" />
                                      </Th> */}
                      <Th>Empresa (CNPJ)</Th>
                      <Th>Ordem</Th>
                      {/* <Th>Número OMIE</Th> */}
                      <Th>Erro</Th>

                      <Th w="8"></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.map((i) => (
                      <Tr key={i.id}>
                        {/* <Td px={["4","4","6"]}>
                                          <Checkbox colorScheme="pink" />
                                      </Td> */}
                        <Td>
                          <Box>
                            <Link color="blue.500">
                              <Text fontWeight="bold">{i?.companyDocument || 'não informado'}</Text>
                            </Link>
                          </Box>
                        </Td>

                        <Td>
                          <Box>
                            <Link color="blue.500">
                              <Text fontWeight="bold">{i?.orderId || 'não informado'}</Text>
                            </Link>
                          </Box>
                        </Td>

                        {/* <Td>
                          <Box>
                            <Link color="blue.500">
                              <Text fontWeight="bold">{i?.registeredOrderIdOnOmie || 'não informado'}</Text>
                            </Link>
                          </Box>
                        </Td> */}

                        <Td>
                          <Box>
                            <Link color="blue.500">
                              <Text fontWeight="bold">{i?.errorMessage || 'não informado'}</Text>
                            </Link>
                          </Box>
                        </Td>

                        <Td>
                          <Flex>

                          {isWideScreen
                            ? (
                              <Button
                                as="button"
                                onClick={() => setSelectedItemRefresh(i)}
                                size="sm"
                                fontSize="sm"
                                colorScheme="teal"
                                marginRight={5}
                                leftIcon={
                                  <Icon as={MdOutlineRefresh} fontSize="16" />
                                }
                              >
                                Reprocessar
                              </Button>
                              )
                            : (
                              <IconButton
                                colorScheme="teal"
                                marginRight={5}
                                aria-label="Reproccess Content"
                                icon={<Icon as={MdOutlineRefresh} size="30" />}
                                onClick={() => setSelectedItemRefresh(i)}
                              />
                              )}

                            {isWideScreen
                              ? (
                              <Button
                                as="button"
                                onClick={() => setSelectedItem(i)}
                                size="sm"
                                fontSize="sm"
                                colorScheme="red"
                                leftIcon={
                                  <Icon as={AiOutlineDelete} fontSize="16" />
                                }
                              >
                                Excluir
                              </Button>
                                )
                              : (
                              <IconButton
                                colorScheme="red"
                                aria-label="Edit Content"
                                icon={<Icon as={AiOutlineDelete} size="30" />}
                                onClick={() => setSelectedItem(i)}
                              />
                                )}
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>

                <Pagination
                  totalCountOfRegisters={total}
                  currentPage={page}
                  onPageChange={setPage}
                />
              </>
            )}
          </Box>
        </Flex>
        </Flex>
        </Flex>
    </Box>
  )
}

export { PoisonedRegistersList }
