import React, { FC } from 'react'
import { Box, Spinner } from '@chakra-ui/react'

const Loader: FC = () => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="rgba(0, 0, 0, 0.5)"
      zIndex={9999}
    >
      <Spinner color="white" size="xl" />
    </Box>
  )
}

export { Loader }
