import React, { FC, useRef, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import {
  DragAndDrop,
  DragAndDropExportedProps
} from '../../Components/Form/DragAndDrop'
import { DataProps } from '../Categories'
import { TextArea } from '../../Components/Form/TextArea'
import { Select } from '../../Components/Form/Select'

interface OptionsProps {
  label: string
  value: string
}

const createFormSchema = Yup.object().shape({
  title: Yup.string().required('Título do vídeo é obrigatório'),
  description: Yup.string().required('Descrição é obrigatório'),
  category_id: Yup.string().required('Categoria é obrigatório'),
  link: Yup.string().required('Link é obrigatório')
})

const updateFormSchema = Yup.object().shape({
  title: Yup.string().required('Título do vídeo é obrigatório'),
  description: Yup.string().required('Descrição é obrigatório'),
  category_id: Yup.string().required('Categoria é obrigatório'),
  link: Yup.string().required('Link é obrigatório')
})

const VideosCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [initialCover, setInitialCover] = useState(undefined)
  const [optionsCategories, setOptionsCategories] = useState<OptionsProps[]>(
    []
  )
  const [initialCoverHorizontal, setInitialCoverHorizontal] = useState(undefined)
  const params = useParams()
  const [video, setVideo] = useState<any>({})

  const toast = useToast()
  const DragRef = useRef<DragAndDropExportedProps>(null)
  const DragHorizontalRef = useRef<DragAndDropExportedProps>(null)

  const navigate = useNavigate()

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createFormSchema)
  })
  const { errors } = formState

  const handleData: SubmitHandler<any> = useCallback(
    async (values) => {
      console.log('entrou: ', values)
      setLoading(true)
      try {
        const { title, description, category_id, link } = values
        const { slug } = params
        const payload = {
          title,
          description,
          category_id,
          link
        }

        const response = slug
          ? await api.put(`/videos/${video?.id}`, payload)
          : await api.post('/videos', payload)

        const id = response.data.video.id

        DragRef?.current?.execute(`/videos/${id}`, () => {
          DragHorizontalRef?.current?.execute(`/videos/horizontal/${id}`, () => {
            navigate('/videos')
          })
        })

        toast({
          title: 'Fazendo o upload da imagem...',
          position: 'top',
          isClosable: true,
          status: 'info',
          variant: 'solid'
        })
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setLoading(false)
      }
    },
    [toast, params, navigate, video]
  )

  const loadItem = useCallback(async () => {
    try {
      const { slug } = params
      if (!slug) return
      const resp = await api.get(`/videos/eps-${slug}`)
      const data: any = resp.data
      console.log('data edit: ', data)
      setValue('title', data.title)
      setValue('description', data.description)
      setValue('link', data.link)
      setValue('category_id', data.category_id)
      setVideo(data)

      if (data.cover_url) {
        setInitialCover(data.cover_url)
      }

      if (data.cover_horizontal_url) {
        setInitialCoverHorizontal(data.cover_horizontal_url)
      }
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params])

  useEffect(() => {
    if (params.slug) {
      loadItem()
    }
  }, [params])

  const loadCategories = useCallback(async () => {
    try {
      const resp = await api.get('/categories', {
        params: {
          pag: 1,
          all: true
        }
      })
      const data: DataProps[] = resp.data.data

      const options = data.map((i: DataProps) => ({
        label: i.category.name,
        value: i.category.id
      }))

      setOptionsCategories(options)
    } catch (error) {
      console.log('error : ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  useEffect(() => {
    loadCategories()
  }, [])

  return (
    <Box>
      <Header />

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handleData)}
        >
          <Heading size="lg" fontWeight="normal" color="blue.500">
            Criar novo Vídeo
          </Heading>
          <Divider my="6" borderColor="blue.700" />

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Input
                label="Título"
                placeholder="Preencha o título do vídeo"
                {...register('title')}
              />
              <Input
                label="Link"
                placeholder="Preencha o link do vídeo"
                // errors={errors.email}
                {...register('link')}
              />
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Select
                label="Categoria"
                placeholder="Selecionar Categoria"
                // errors={errors.gym_id}

                options={optionsCategories}
                {...register('category_id')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
              <DragAndDrop
                  label="Imagem horizontal do vídeo"
                  ref={DragHorizontalRef}
                  defaultCover={initialCoverHorizontal}
              />

              <DragAndDrop
                label="Imagem vertical do vídeo"
                ref={DragRef}
                defaultCover={initialCover}
              />
            </SimpleGrid>

            <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">
              <TextArea
                label="Descrição"
                placeholder="Preencha a descrição"
                {...register('description')}
              />
            </SimpleGrid>
          </VStack>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              {loading
                ? (
                <Spinner />
                  )
                : (
                <>
                  <Button
                    as="a"
                    bg="red.primary"
                    _hover={{ bgColor: 'gray.500' }}
                    color="white"
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="submit"
                    bg="blue.500"
                    _hover={{ bgColor: 'gray.500' }}
                    color="white"
                    isLoading={formState.isSubmitting}
                  >
                    Salvar
                  </Button>
                </>
                  )}
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export { VideosCreate }
