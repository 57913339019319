import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { useAuth } from '../Hooks/Auth'
import { ClauseProposalList } from '../Pages/ClauseProposal'
import { ClauseProposalCreate } from '../Pages/ClauseProposal/create'
import { CollaboratorsList } from '../Pages/Collaborators'
import { CollaboratorsCreate } from '../Pages/Collaborators/create'
import { Dashboard } from '../Pages/Dashboard'
import { BillsToPayList } from '../Pages/BillsToPay'
import { BillsToPayCreate } from '../Pages/BillsToPay/create'
import { TimesheetCreate } from '../Pages/Timesheet'
import { NotFoundPage } from '../Pages/NotFound'
import { NotificationsList } from '../Pages/Notifications'
import { PreProposalList } from '../Pages/PreProposal'
import { PreProposalCreate } from '../Pages/PreProposal/create'
import { ProcessList } from '../Pages/Process'
import { ProcessCreate } from '../Pages/Process/create'
import { ProductsList } from '../Pages/Products'
import { ProductsCreate } from '../Pages/Products/create'
import { ProposalList } from '../Pages/Proposal'
import { ProposalCreate } from '../Pages/Proposal/create'
import { ProvidersList } from '../Pages/Providers'
import { ProvidersCreate } from '../Pages/Providers/create'
import { ServicesList } from '../Pages/Services'
import { ServicesCreate } from '../Pages/Services/create'
import { SignIn } from '../Pages/SignIn'
import { Lists } from '../Pages/Lists'
import { UnitiesCreate } from '../Pages/Lists/create'
import { UsersList } from '../Pages/Users'
import { VideosList } from '../Pages/Videos'
import { VideosCreate } from '../Pages/Videos/create'
import { BillsToReceiveList } from '../Pages/BillsToReceive'
import { BillsToReceiveCreate } from '../Pages/BillsToReceive/create'
import { VacationsCreate } from '../Pages/Vacations'
import { PoisonedRegistersList } from '../Pages/Registers'

const AppRoutes: FC = () => {
  const { user } = useAuth()

  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/lists" element={<Lists />} />

      <Route path="/services" element={<ServicesList />} />
      <Route path="/services/create" element={<ServicesCreate />} />
      <Route
        path="/services/create/:service_id"
        element={<ServicesCreate />}
      />

      <Route path="/clause-proposal" element={<ClauseProposalList />} />
      <Route path="/clause-proposal/create" element={<ClauseProposalCreate />} />
      <Route
        path="/clause-proposal/create/:id"
        element={<ClauseProposalCreate />}
      />

      <Route path="/timesheet" element={<TimesheetCreate />} />

      <Route path="/videos" element={<VideosList />} />
      <Route path="/videos/create" element={<VideosCreate />} />
      <Route path="/videos/create/:slug" element={<VideosCreate />} />

      <Route path="/products" element={<ProductsList />} />
      <Route path="/products/create" element={<ProductsCreate />} />
      <Route
        path="/products/create/:slug"
        element={<ProductsCreate />}
      />

      <Route path="/registers" element={<PoisonedRegistersList />} />

      <Route path="/users" element={<UsersList />} />
      <Route path="/" element={<SignIn />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
